<template>
  <div class="container-fluid h-100 d-flex flex-column scrollable-table p-0">
    <div class="text-center pb-3">
      <h2>Logs</h2>
    </div>
    <div class="row">
      <div class="form-group col-3" style="margin: 20px 0px">
        <label>Level</label>
        <select @input="updateLevel($event.target.value)" class="form-select">
          <option :selected="level === 'Information'" value="Information">
            Information
          </option>
          <option :selected="level === 'Warning'" value="Warning">
            Warning
          </option>
          <option :selected="level === 'Error'" value="Error">Error</option>
        </select>
      </div>
      <div class="form-group col-3" style="margin: 20px 0px">
        <label>From</label>
        <input
          class="form-control"
          type="datetime-local"
          @change="updateFrom($event.target.value)"
        />
      </div>
      <div class="form-group col-3" style="margin: 20px 0px">
        <label>To</label>
        <input
          class="form-control"
          type="datetime-local"
          @change="updateTo($event.target.value)"
        />
      </div>
      <div class="form-group col-3" style="margin: 20px 0px">
        <label>Count</label>
        <input
          class="form-control"
          type="number"
          v-model="count"
          @change="updateCount($event.target.value)"
        />
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead class="table-light">
          <tr>
            <th style="width: 200px">Time stamp</th>
            <th style="width: 300px">Message</th>
            <th style="width: 500px">Exception</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log of getLogs" :key="log.id">
            <td style="width: 200px">{{ log.timeStamp }}</td>
            <td style="width: 300px; word-break: break-all">
              {{ log.message }}
            </td>
            <td>{{ log.exception }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Logs",
  data() {
    return {
      key: 1,
    };
  },
  async setup() {
    const store = useStore();
    let model = {
      count: 20,
      from: undefined,
      to: undefined,
      level: "Error",
    };
    await store.dispatch("loadLogs", model);
    return model;
  },
  computed: {
    getLogs() {
      return this.$store.getters["getLogs"];
    },
  },
  methods: {
    async loadLogs(model) {
      await this.$store.dispatch("loadLogs", model);
      this.$forceUpdate();
    },
    async updateLevel(level) {
      this.level = level;
      await this.loadLogs({
        level: this.level,
        to: this.to,
        from: this.from,
        count: this.count,
      });
    },
    async updateFrom(from) {
      this.from = from !== "" ? from : undefined;
      await this.loadLogs({
        level: this.level,
        to: this.to,
        from: this.from,
        count: this.count,
      });
    },
    async updateTo(to) {
      this.to = to !== "" ? to : undefined;
      await this.loadLogs({
        level: this.level,
        to: this.to,
        from: this.from,
        count: this.count,
      });
    },
    async updateCount(count) {
      this.count = count > 0 ? (count < 1000 ? count : 1000) : 1;
      await this.loadLogs({
        level: this.level,
        to: this.to,
        from: this.from,
        count: this.count,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
